<template>
    <div class="homeOrder">
        <div class="title">我的寻源</div>


        <div class="detail">
            <div class="title">我的寻源详情</div>
            <div class="img_info">
                <div class="img">
                    <img :src="sourcingData.demandImgs?.split(`,`)[0]" alt="">
                </div>
                <div class="info">
                    <div class="son"><span>产品名称：</span> {{ sourcingData.productName }}</div>
                    <div class="son"><span>商品类目：</span> {{ sourcingData.categoryName }}</div>
                    <div class="son"><span>当前状态：</span> {{ type }}</div>
                    <div class="son"><span>需求数量：</span> {{ sourcingData.purchaseNum }}</div>
                    <div class="son"><span>单价上限：</span> {{ sourcingData.ceilingPrice }}</div>
                    <div class="son"><span>订单总额：</span> {{ sourcingData.orderPrice }}</div>
                    <div class="son"><span>发布时间：</span> {{ sourcingData.gmtCreate }}</div>
                    <div class="son"><span>截止时间：</span> {{ sourcingData.gmtDeadline }}</div>
                    <div class="son"><span>详情描述：</span>
                        <div>{{ sourcingData.description }}</div>
                    </div>
                </div>
            </div>

            <div class="baoj_dete">
                <div class="head">报价详情</div>
                <div class="img_co">
                    <div class="img">
                        <img :src="mallStoreObj.mallSku?.img" alt="">
                    </div>
                    <div class="info">
                        <div class="so">
                            <div class="zt">
                                <div class="tit">供货商：</div>
                                <div class="name">{{ mallStoreObj.mallStore?.storeName }}</div>
                            </div>
                        </div>
                        <div class="so">
                            <div class="zt" style="width: 100%;">
                                <div class="tit">商品名称：</div>
                                <div class="text">{{ mallStoreObj.mallSpu?.title }}</div>
                            </div>
                        </div>
                        <div class="so">
                            <div class="zt">
                                <div class="tit">报价总金额：</div>
                                <div class="jg">{{mallStoreObj.totalPrice }}</div>
                            </div>
                            <div class="zt">
                                <div class="tit">供货数量：</div>
                                <div class="text">{{ mallStoreObj.offerNumV }}</div>
                            </div>
                        </div>
                        <div class="so">
                            <div class="zt">
                                <div class="tit">订单单价：</div>
                                <div class="text">{{mallStoreObj.unitPrice }}</div>
                            </div>
                            <div class="zt">
                                <div class="tit">运费金额：</div>
                                <div class="text">{{ mallStoreObj.freightAmount }}</div>
                            </div>
                        </div>
                        <div class="so">
                            <div class="zt" style="width: 100%;">
                                <div class="tit">送货地址：</div>
                                <div class="text">{{ sourcingData.deliveryProvince }}{{ sourcingData.deliveryCity }}{{ sourcingData.deliveryCounty }}{{ sourcingData.deliveryAddress }}</div>
                            </div>
                        </div>
                        <div class="so">
                            <div class="zt">
                                <div class="tit">预计发货时间：</div>
                                <div class="text">{{ mallStoreObj.gmtDelivery }}</div>
                            </div>
                        </div>
                        <div class="so">
                            <div class="zt" style="width: 100%;">
                                <div class="tit">备注：</div>
                                <div class="text">{{ mallStoreObj.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ljxia" @click="xiadanClick"  v-if="!mallStoreObj.oid">立即下单</div>
                <div class="ljxia" v-else>已下单</div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: 'mySourcingOfferDetails',
    data() {
        return {
            sourcingData: {},
            mallStoreObj: {},
            type: ''
        }
    },
    mounted() {
        this.sourcingData = JSON.parse(localStorage.getItem('sourcingData'));
        this.mallStoreObj = this.sourcingData.mallPurchaseOfferList[this.$route.query.index]
        if (this.sourcingData.demandStatus == 'PROCESSING') this.type = '进行中'
        if (this.sourcingData.demandStatus == 'TIMEOUT') this.type = '已超时'
        if (this.sourcingData.demandStatus == 'CANCEL') this.type = '已取消'
        if (this.sourcingData.demandStatus == 'FINISHED') this.type = '已完成'
        console.log(this.sourcingData)
    },
    methods:{
        xiadanClick(){
            if(this.type == '已取消') return this.$message.warning('该寻源已取消')
            if(this.type == '已超时') return this.$message.warning('该寻源已超时')
            if(this.type == '已完成') return this.$message.warning('该寻源已完成')
            console.log(this.mallStoreObj)
            this.$set(this.mallStoreObj,'address',`${this.sourcingData.deliveryProvince} ${this.sourcingData.deliveryCity} ${this.sourcingData.deliveryCounty} ${this.sourcingData.deliveryAddress} `)
            localStorage.setItem('mallStoreObjXY', JSON.stringify(this.mallStoreObj));
            this.$router.push('/payment')
        }
    }
}
</script>

<style lang="less" scoped>
.homeOrder {
    width: 1050px;
    height: auto;

    >.title {
        width: 100%;
        height: 40px;
        background-color: #fff;
        padding: 10px 15px;
        box-sizing: border-box;
        font-size: 16px;
        color: #333;
        font-weight: bold;
    }

    >.detail {
        width: 100%;
        height: auto;
        background-color: #fff;
        padding: 20px;
        box-sizing: border-box;

        >.title {
            width: 100%;
            font-size: 14px;
            color: #000;
        }

        >.img_info {
            width: 100%;
            height: auto;
            display: flex;
            margin-top: 20px;

            >.img {
                width: 320px;
                height: 320px;

                >img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            >.info {
                width: 620px;
                height: auto;
                margin-left: 20px;

                >.son {
                    width: 100%;
                    display: flex;
                    font-size: 14px;
                    color: #333;
                    margin-bottom: 10px;

                    >span {
                        color: #666;
                        margin-right: 5px;
                    }

                    >div {
                        width: 540px;
                    }
                }
            }
        }

        >.baoj_dete {
            width: 100%;
            height: 484px;
            border: 1px solid #D8D8D8;

            >.head {
                width: 100%;
                height: 40px;
                text-align: center;
                line-height: 40px;
                background-color: #EEEEEE;
                border-bottom: 1px solid #D8D8D8;
                color: #333;
                font-size: 20px;
                font-weight: bold;
            }

            >.img_co {
                width: 100%;
                height: 444px;
                display: flex;
                align-items: center;

                >.img {
                    width: 320px;
                    height: 320px;

                    >img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                >.info {
                    width: 550px;
                    height: auto;
                    margin-left: 20px;

                    >.so {
                        width: 100%;
                        display: flex;
                        margin-bottom: 20px;

                        .zt {
                            width: 50%;
                            display: flex;

                            >.tit {
                                width: 100px;
                                font-size: 14px;
                                color: #666;
                            }

                            >.name {
                                font-size: 16px;
                                color: #333;
                                font-weight: bold;
                            }

                            >.jg {
                                color: #FF4242;
                                font-size: 16px;
                                font-weight: bold;
                            }

                            >.text {
                                font-size: 14px;
                                color: #333;
                            }
                        }
                    }
                }

            }

            >.ljxia {
                width: 120px;
                height: 40px;
                background: #FF4242;
                border-radius: 4px 4px 4px 4px;
                margin: 0 auto;
                margin-top: -50px;
                font-size: 14px;
                color: #fff;
                text-align: center;
                line-height: 40px;
                cursor: pointer;
            }
        }
    }


}
.btn2QX {
    border: 1px solid #BFBFBF;
    background: #f4f4f4;
    color: #666;
}
</style>